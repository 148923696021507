import type { Application } from '@src/@types/app';
import { DEFAULT_PRELOADED_LIMIT, FILTERS_NAMES } from '@src/constants/filters';
import type { ListKeys } from '@src/constants/microcredits';
import { fetchOffers } from '@src/reducers/microcredits';
import { isServer } from '@src/utils';

import { getPageListQuery } from './getPageListQuery';

interface IListParams {
    key: ListKeys;
    defaultFiltersMode: string;
}

export const getPageListData = async (ctx: Application.ReduxNextPageContext, listParams: IListParams) => {
    const {
        store: { dispatch },
    } = ctx;
    const { key, defaultFiltersMode } = listParams;

    const filterParams = getFilterParams(ctx);

    const pageListQuery = getPageListQuery({ ctx, defaultFiltersMode });

    await dispatch(
        fetchOffers({
            query: pageListQuery,
            params: filterParams,
            key,
            ...(isServer && { preloadedLimit: DEFAULT_PRELOADED_LIMIT }),
        }),
    );
};

function getFilterParams(ctx: Application.ReduxNextPageContext) {
    const { hasUtmLabel } = ctx.store.getState().route;
    const phoneNumber = ctx.cookie.get()._PN_;
    const hasChecker = ctx.params.pathname === '/zaimy/' && Boolean(phoneNumber);

    return {
        [FILTERS_NAMES.LOCATION]: ctx.params.locationRoute,
        [FILTERS_NAMES.METADATA]: true,
        [FILTERS_NAMES.ROUTE]: { asPath: ctx.asPath ?? '', query: ctx.query ?? {} },
        ...(!hasUtmLabel && {
            [FILTERS_NAMES.ADS_ONLY]: true,
            [FILTERS_NAMES.HAS_OFFERS_DUPLICATE]: true,
        }),
        ...(hasChecker && {
            [FILTERS_NAMES.PHONE_NUMBER]: phoneNumber,
        }),
    };
}
