import type { NextPage } from 'next';
import dynamic from 'next/dynamic';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import type { DialogCloseEvent } from '@sravni/react-design-system/dist/types/components/Dialog/types/Dialog.types';
import { useIsMobile } from '@sravni/react-utils';

import type { Application } from '@src/@types/app';
import BottomBannerContainer from '@src/components/BottomBannerContainer';
import { PageContext } from '@src/config';
import { getPageListData } from '@src/config/getPageListData';
import type { IPageContextConfig } from '@src/config/PageContext';
import { BottomBannerTypes } from '@src/constants/bottomBannerTypes';
import { MODES } from '@src/constants/filters';
import { ListKeys } from '@src/constants/microcredits';
import { PageHeader } from '@src/containers/list/PageHeader';
import ProductList from '@src/containers/list/ProductList';
import { useBestProducts } from '@src/hooks/useBestProducts';
import { useListPage } from '@src/hooks/useListPage';
import { useLoadRestOffers } from '@src/hooks/useLoadRestOffers';
import { closeAuthDialog, useAuthDialog } from '@src/reducers/authDialog';
import { getHasUtmLabel } from '@src/reducers/route';
import { isServer } from '@src/utils/isServer';

import styles from './styles.module.scss';

const SeoComponents = dynamic(() => import('@src/components/SeoComponents'));
const AuthDialog = dynamic(() => import('@src/components/AuthDialog'));

const pageConfig: IPageContextConfig = {
    listKey: ListKeys.LIST,
    source: 'search',
    sortPanelVisible: true,
    seoLinksVisible: true,
    extraFiltersVisible: true,
    cardTermIsVisible: true,
    withBanners: true,
    withInvitationBanner: true,
    withEmailBurner: true,
    withSelectionBanner: true,
    withCardProductLink: true,
    isMainPage: true,
};

const ListPage: NextPage = () => {
    const isMobile = useIsMobile();
    const { filter, seo, pathname } = useListPage(ListKeys.LIST);
    const config = useMemo(() => ({ ...pageConfig, withMonetizationCardLogoClick: isMobile }), [isMobile]);
    const dispatch = useDispatch();

    const { isOpen } = useAuthDialog();

    useLoadRestOffers(ListKeys.LIST);
    useBestProducts();

    const handleAuthorizeDialogClose = useCallback(
        (event: DialogCloseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            dispatch(closeAuthDialog());
        },
        [dispatch],
    );

    return (
        <PageContext.Provider value={config}>
            <PageHeader className={styles.page_header} seo={seo} filter={filter} />
            <ProductList className={styles.product_list} filter={filter} pathname={pathname} />
            {seo && <SeoComponents seo={seo} breadcrumbs={seo.breadcrumbs} prefooter={seo.prefooter} />}
            <AuthDialog visible={isOpen} onClose={handleAuthorizeDialogClose} />
            <BottomBannerContainer enabledTypes={[BottomBannerTypes.OUT_OF_PAGE, BottomBannerTypes.SIDEBAR]} />
        </PageContext.Provider>
    );
};

ListPage.getInitialProps = (ctx: Application.ReduxNextPageContext) => {
    if (isServer) return {};

    const hasUtmLabel = getHasUtmLabel(ctx.store.getState());

    return getPageListData(ctx, {
        key: ListKeys.LIST,
        defaultFiltersMode: hasUtmLabel ? MODES.AS_UTM : MODES.DEFAULT,
    });
};

export default ListPage;
